.App {
  text-align: center;
  background: #00272B;
  color: #FF6663;
  padding: 2rem;
  font-size: 1.2rem;
}

#root {
  /* background: #FFBBBB; */
  background: #00272B;
}

body {
  font-family: "Calibri";
  position: relative;
  min-height: 100%;
  min-height: 100vh;
}

.main {
  position: relative;
  margin-left: 3rem;
  margin-right: 3rem;
  background: #F5E5E5;
  color: #00272B;
}

.footer {
  background: #65D4C4;
  color: #00272B;
  font-size: 1.2rem;
  margin-left: 3rem;
  margin-right: 3rem;
}

.footer-content {
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 2rem;
  padding-top: 1rem;
  border-top: solid #00272B 1px;
}

.festivals-content {
  background: #F5E5E5;
  color: #00272B;
  padding: 2rem;
  font-size: 1.2rem;
}

.conferences-content {
  background: #F5E5E5;
  color: #00272B;
  padding: 2rem;
  font-size: 1.2rem;
}

.concerts-content {
  background: #F5E5E5;
  color: #00272B;
  padding: 2rem;
  font-size: 1.2rem;
}

.navigation {
  text-align: center;
  display: flex;
  background: #65D4C4;
  color: #00272B;
  font-size: 1.4rem;
  /* position: absolute; */
  /* top: 0; */
}

.nav-elem {
  text-decoration: none;
  color: black;
  display: inline;
  padding: 1rem;
  cursor: pointer;
}

.nav-elem-home {
  text-decoration: none;
  color: black;
  display: inline;
  padding: 1rem;
}


.nav-elem:hover {
  background: #F5E5E5AA;
}

.sub-nav {
  padding: 0.25rem;
  /* background: #24C3B3; */
  background: #75E4D4;
  z-index: 2;
  position: absolute;
  top: 55px;
  box-shadow: 2px 2px 2px #22777777;
}

.sub-nav-festival {
  margin-left: 9rem;
}

.sub-nav-conferences {
  margin-left: 18rem;
}

.sub-nav-concerts {
  margin-left: 30rem;
}

.sub-nav-artistDevelopment {
  margin-left: 40rem;
}

.sub-nav-yourFirstTour {
  margin-left: 56rem;
}

@media (max-resolution: 120dpi) {
  .sub-nav-festival {
    margin-left: 10vw;
  }

  .sub-nav-conferences {
    margin-left: 25vw;
  }

  .sub-nav-concerts {
    margin-left: 40vw;
  }

  .sub-nav-artistDevelopment {
    margin-left: 60vw;
  }

  .sub-nav-yourFirstTour {
    margin-left: 85vw;
  }
}

.nav-sub-elem {
  display: block;
  text-decoration: none;
  padding: 0.5rem;
  background: #F5E5E5AA;
}

.nav-sub-elem:hover {
  background: #EEEEEE22;
}

.nav-sub-elem:link {
  text-decoration: none;
  color: black;
}

.nav-sub-elem:visited {
  text-decoration: none;
  color: black;
}

.navLogo {
  height: 1.5rem;
}

.general-images {
  max-width: 360px;
  max-height: 360px;
  object-fit: scale-down;
  padding: 10px;
}

.span-for-side-pics {
  display: flex;
}

.introPic {
  width: 300px;
}

.logo {
  width: 50%;
}

/* Mobile nav menu */
.nav-mobile {
  display: flex;
  flex-direction: column;
  background: #65D4C4;
  color: #00272B;
  font-size: 1.4rem;
}

.ham-container {
  padding: 1rem;
}

@media (max-width: 963px) {
  .navigation {
    display: none;
  }
  .span-for-side-pics {
    display: block;
  }
  .sub-nav {
    padding: 0.25rem;
    background: #75E4D4;
    z-index: 2;
    position: absolute;
    top: 365px;
  }

  .sub-nav-festival {
    margin-left: 1rem;
  }

  .sub-nav-conferences {
    margin-left: 1rem;
  }

  .sub-nav-concerts {
    margin-left: 1rem;
  }

  .sub-nav-artistDevelopment {
    margin-left: 1rem;
  }

  .sub-nav-yourFirstTour {
    margin-left: 1rem;
  }
}

@media (max-width: 640px) {
  .main {
    margin-left: 0.1rem;
    margin-right: 0.1rem;
  }

  .footer {
    margin-left: 0.1rem;
    margin-right: 0.1rem;
  }

  .general-images {
    max-width: 300px;
    max-height: 300px;
  }

  .mobile-link {
    font-size: 1rem;
  }

  iframe {
    max-width: 300px;
    max-height: 300px;
  }
}

@media (min-width: 963px) {
  .nav-mobile {
    display: none;
  }
  .nav-container > div {
    display: none;
  }
  .ham-container > div {
    display: none;
  }
  .hamburgerDropDown {
    display: none;
  }
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.icon-container {
  display: flex;
  text-align: center;
  align-items: center;
}

.icon {
  height: 20px;
  width: auto;
  padding: 5px;
}

.App-link {
  color: #61dafb;
}

.nomoresad {
  height: 50px;
  object-fit: scale-down;
  padding: 10px;
}

.nomoresadimg {
  max-height: 50px;
  object-fit: scale-down;
}
/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
/*
.general-images {
  max-width: 360px;
  max-height: 360px;
  object-fit: scale-down;
}

.span-for-side-pics {
  display: flex;
}

.introPic {
  width: 300px;
}

.logo {
  width: 50%;
} */
